import React from "react";
// import site01 from "../img/main/site01.png";
import site01 from "../img/main/site01.jpeg";
import home3 from "../img/main/home3.png";
// import home2 from "../img/main/home2.png";
import site02 from "../img/main/site02.png";
import site04 from "../img/main/site04.png";
import site02test from "../img/main/site02-12.png";
import icon_arrow_right from "../img/main/icon_arrow_right.png";
import icon_arrow_left from "../img/main/icon_arrow_left.png";
import styled from "styled-components";
import { Carousel } from "antd";

const Section_Second = () => {
  return (
    <StyledMain>
      <Carousel arrows autoplay dots={false} className="slide__layout">
        <div className="inner1">
          <div
            className="slide1"
            data-anchor="slide1"
            style={{
              padding: "0em 0 10em 0",
            }}
          >
            <div className="img1">
              <img src={home3} alt="Site 01" />
            </div>
            <div
              className="text"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="font_gw">나비톡</p>
              <span className="naviatalk__content">
                우리끼리만 아는 나만의 비밀톡
              </span>
              <a
                href="http://www.navitalk.co.kr"
                target="_blank"
                rel="noopener noreferrer"
                className="circle_btn"
              >
                WEBSITE
              </a>
            </div>
          </div>
        </div>
        <div className="inner2">
          <div
            className="slide2"
            data-anchor="slide2"
            style={{
              padding: "5em 0 10em 0",
            }}
          >
            <div className="img2">
              <img src={site02} alt="Site 02" />
            </div>
            <div
              className="text"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="font_gw">엠애드컴퍼니</p>
              <span
                style={{
                  textAlign: "center",
                }}
              >
                소통과 실행능력이 우선인 광고대행사
              </span>
              <a
                href="http://madcompany.co.kr/"
                target="_blank"
                rel="noopener noreferrer"
                className="circle_btn"
              >
                WEBSITE
              </a>
            </div>
          </div>
        </div>
        <div className="inner2">
          <div
            className="slide"
            data-anchor="slide3"
            style={{
              padding: "5em 0 10em 0",
            }}
          >
            <div className="img2">
              <img src={site02test} alt="Site 02" />
            </div>
            <div
              className="text"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="font_gw">나비맥 (NAVIMAG)</p>
              <span style={{ textAlign: "center" }}>
                나비맥 (NAVIMAG)에서 다양한 컨텐츠,
                <br />
                트렌디한 뷰티 숏폼을 만나보세요.
              </span>
              <a
                href="https://f5clinic.co.kr/"
                target="_blank"
                rel="noopener noreferrer"
                className="circle_btn"
              >
                WEBSITE
              </a>
            </div>
          </div>
        </div>
      </Carousel>
    </StyledMain>
  );
};

export default Section_Second;

const StyledMain = styled.div`
  padding: 2em 0 0 0;
  .react-fullpage__contents {
    min-height: 0%;
  }

  .slide__layout {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img1 {
    display: flex;
    justify-content: center;
  }

  .img1 img {
    height: 52em;
  }

  .img2 {
    display: flex;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  .slick-arrow.slick-next::after,
  .slick-arrow.slick-prev::after {
    content: ""; /* 기존 텍스트 컨텐츠를 지웁니다 */
    display: block;
    width: 50px; /* 이미지 크기를 조정 */
    height: 50px; /* 이미지 크기를 조정 */
    background-size: contain; /* 이미지가 요소 안에 맞게 조정되도록 설정 */
    background-repeat: no-repeat; /* 이미지가 반복되지 않도록 설정 */
    position: absolute;
    transform: translate(-50%, -50%); /* 중앙 정렬 */
  }

  .slick-arrow.slick-next::after {
    background-image: url(${icon_arrow_right}); /* 임포트된 오른쪽 화살표 이미지 */
    left: -1em; /* 위치 조정 */
  }

  .slick-arrow.slick-prev::after {
    background-image: url(${icon_arrow_left}); /* 임포트된 왼쪽 화살표 이미지 */
    left: 2em; /* 위치 조정 */
  }

  .slide1 {
    display: flex;
    justify-content: center;
    gap: 5em;
  }

  .slide2 {
    display: flex;
    justify-content: center;
    gap: 5em;
  }

  .slide {
    display: flex;
    justify-content: center;
    gap: 5em;
  }

  // 화살표가 이미지가 겹치는 순간
  @media (max-width: 1201px) {
    padding: 0 0 0 0;

    .img2 img {
      width: 80%;
    }

    .slide2,
    .slide {
      gap: 0.1em;
      padding: 5em 4em 10em 4em !important;
    }

    // .img2 img {
    //   width: 45%; /* 이미지가 부모 요소에 맞춰 100% 너비로 조정됨 */
    //   height: auto; /* 너비 비율에 따라 자동으로 높이 조절 */
    //   max-width: 90%; /* 최대 너비를 90%로 제한 (여백을 위해) */
    //   margin: 0 auto; /* 이미지가 가운데로 정렬 */
    // }

    .slide1 img {
      height: 45em;
    }
  }

  @media (max-width: 470px) {
    padding: 0 0 0 0;

    .slide__layout {
      // height: 50vh;
      // display: block;
      // margin-top: 5vh;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }

    .slide1 {
      display: block;
    }

    .slide2,
    .slide {
      display: block;
      padding: 5em 0em 10em 0em !important;
    }

    .slick-arrow.slick-next::after,
    .slick-arrow.slick-prev::after {
      display: none;
    }

    .img2 img {
      width: 100%; /* 이미지가 부모 요소에 맞춰 100% 너비로 조정됨 */
      height: auto; /* 너비 비율에 따라 자동으로 높이 조절 */
      max-width: 90%; /* 최대 너비를 90%로 제한 (여백을 위해) */
    }

    .slide1 img {
      height: auto;
      max-height: 30em; /* 높이를 제한하여 화면을 벗어나지 않도록 */
    }

    .circle_btn {
      // position: relative;
      position: absolute;
      bottom: 7%;
      display: inline-block;
      text-align: center;
      width: 85px;
      height: 85px;
      border-radius: 50%;
      padding: 0;
      margin-top: 20px;
      line-height: 85px;
      color: #fff !important;
      font-size: 0.8em;
      background: #2fb4cb;
      font-weight: 600;
    }

    // .naviatalk__content {
    //   margin-bottom: 20em;
    // }
  }
`;
